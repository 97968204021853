import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Link } from 'gatsby'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  created?: string
  languageCode: string
  thumb?: ImageProps
  title?: string
  URL: string
}

export const Post = memo(function Post({
  created,
  languageCode,
  thumb,
  title,
  URL,
}: Props) {
  if (!URL) {
    return null
  }

  return (
    <Container to={URL}>
      <Thumb>{thumb ? <LazyLoadImage effect="blur" {...thumb} /> : null}</Thumb>
      <Text>
        {created ? (
          <Created>
            {new Date(created).toLocaleDateString(languageCode, {
              year: 'numeric',
              month: 'long',
            })}
          </Created>
        ) : null}
        {title ? <Title className="article-title">{title}</Title> : null}
      </Text>
    </Container>
  )
})

const Container = styled(Link)`
  display: flex;
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.variants.neutralLight2};
  margin-top: 0.625rem;
  padding-top: 0.625rem;
  &:first-of-type {
    border: 0;
    margin-top: 0;
    padding-top: 0;
  }
  &:hover {
    .article-title {
      color: ${({ theme }) => theme.colors.variants.neutralDark1};
    }
  }
`

const Thumb = styled.div`
  width: 22%;
  padding-bottom: 22%;
  background: ${({ theme }) => theme.colors.variants.neutralLight2};
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Text = styled.div`
  width: 78%;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  padding-left: 1.125rem;
`

const Created = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.1125rem;
  line-height: 0.9375rem;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-size: 0.9375rem;
  font-weight: 500;
  letter-spacing: 0.0187rem;
  line-height: 1.25rem;
`
