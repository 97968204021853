import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  image?: ImageProps
}

export const Paragraph = memo(function Paragraph({
  description,
  image,
}: Props) {
  return (
    <Container>
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
      {image ? (
        <ImageWrap>
          <LazyLoadImage effect="blur" {...image} />
        </ImageWrap>
      ) : null}
    </Container>
  )
})

const Container = styled.div``

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark3};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;

  p {
    margin: 2rem 0;
  }
  a {
    text-decoration: underline;
  }
  ul {
    list-style: initial;
    padding-left: 1.125rem;
  }
`

const ImageWrap = styled.div`
  .lazy-load-image-background {
    position: relative;
    top: auto;
    left: auto;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`
