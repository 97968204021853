import styled from '@emotion/styled'
import { Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { theme } from 'app/theme'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { Facebook, Send, Twitter } from 'react-feather'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share'

export interface Props {
  created?: string
  image?: ImageProps
  languageCode: string
  title?: string
  URL: string
}

export const ArticleHero = memo(function ArticleHero({
  created,
  image,
  languageCode,
  title,
  URL,
}: Props) {
  return (
    <Container>
      {image ? <Background effect="blur" {...image} /> : null}
      <Wrapper dial={4} row wrap space="between">
        <LeftSide>
          {created ? (
            <Created>
              {new Date(created).toLocaleDateString(languageCode, {
                year: 'numeric',
                month: 'long',
              })}
            </Created>
          ) : null}
          {title ? <Title>{title}</Title> : null}
        </LeftSide>
        <RightSide dial={4} row>
          <Label>{useVocabularyData('share', languageCode)}</Label>
          <Share row wrap>
            <FacebookShareButton url={URL}>
              <Facebook />
            </FacebookShareButton>
            <TwitterShareButton url={URL}>
              <Twitter />
            </TwitterShareButton>
            <WhatsappShareButton url={URL}>
              <svg
                className="whatsapp"
                xmlns="http://www.w3.org/2000/svg"
                width="25.364"
                height="24.958"
                viewBox="0 0 25.364 24.958"
              >
                <g transform="translate(-8.16 -9.869)">
                  <path
                    d="M40.228,37.524a1.435,1.435,0,0,0-.674-.485l-.189-.092c-.018,0-2.053-1-2.44-1.144a1.011,1.011,0,0,0-1.324.345c-.2.305-.8,1-1.077,1.312-.024.027-.043.04-.043.043a.5.5,0,0,1-.177-.064c-.061-.031-.149-.067-.253-.113A8.662,8.662,0,0,1,31.577,35.8a9.934,9.934,0,0,1-1.864-2.288l-.043-.049a1.112,1.112,0,0,1,.1-.107c.122-.119.253-.278.378-.427l.177-.207a2.521,2.521,0,0,0,.366-.577l.046-.095a1.07,1.07,0,0,0-.027-1.019c-.055-.107-.418-.976-.683-1.611l-.387-.93c-.326-.772-.747-.869-1.138-.872h-.75a1.748,1.748,0,0,0-1.272.58l-.064.067a4.27,4.27,0,0,0-1.278,3.13,7.039,7.039,0,0,0,1.525,3.856l.052.076a15.624,15.624,0,0,0,6.14,5.292,20.572,20.572,0,0,0,2.047.744,5.007,5.007,0,0,0,1.5.226,5.855,5.855,0,0,0,.915-.073,4.061,4.061,0,0,0,2.745-1.94,3.088,3.088,0,0,0,.165-2.041ZM39.2,39.251a3.272,3.272,0,0,1-2.01,1.351,4.508,4.508,0,0,1-2.013-.122,20.269,20.269,0,0,1-1.961-.714,14.663,14.663,0,0,1-5.75-4.981c-.034-.049-.058-.082-.076-.1a6.29,6.29,0,0,1-1.339-3.3,3.373,3.373,0,0,1,1.037-2.5l.073-.076a.845.845,0,0,1,.61-.287h.756c.1,0,.156,0,.287.305.1.229.241.567.39.915.4.958.647,1.55.708,1.672s.049.156.031.192l-.052.1a1.459,1.459,0,0,1-.238.384l-.186.223a4.592,4.592,0,0,1-.305.357.955.955,0,0,0-.226,1.266,10.793,10.793,0,0,0,2.047,2.507A9.606,9.606,0,0,0,33.7,38.134l.21.092a1,1,0,0,0,1.306-.192c.177-.2.891-1.022,1.15-1.4.024-.037.043-.055.046-.061a1.06,1.06,0,0,1,.214.061c.275.1,1.9.882,2.355,1.1l.2.1c.095.043.2.1.268.134A3.327,3.327,0,0,1,39.2,39.251Z"
                    transform="translate(-11.799 -12.337)"
                  />
                  <path
                    d="M30.012,13.725q-.1-.1-.2-.2a12.811,12.811,0,0,0-17.921,0A12.259,12.259,0,0,0,9.969,28.738L8.3,34.116a.46.46,0,0,0,.116.458.452.452,0,0,0,.454.116l5.491-1.644a12.753,12.753,0,0,0,15.45-1.885A12.329,12.329,0,0,0,30.012,13.725Zm-.669,16.6q-.092.094-.186.186v0a11.844,11.844,0,0,1-14.519,1.647.443.443,0,0,0-.238-.067.481.481,0,0,0-.214.052L9.429,33.567,10.874,28.9a.46.46,0,0,0,0-.464,11.353,11.353,0,0,1,1.656-14.26,11.9,11.9,0,0,1,16.627,0A11.417,11.417,0,0,1,29.344,30.324Z"
                    transform="translate(0)"
                  />
                </g>
              </svg>
            </WhatsappShareButton>
            <EmailShareButton url={URL}>
              <Send />
            </EmailShareButton>
          </Share>
        </RightSide>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  height: 83vh;
  max-height: -webkit-fill-available;
  position: relative;
  &:before,
  &:after {
    content: '';
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
  &:before {
    height: 50%;
    background: linear-gradient(
      ${rgba(theme.colors.variants.neutralDark1, 0.42)},
      ${rgba(theme.colors.variants.neutralDark1, 0)}
    );
  }
  &:after {
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.4;
  }
`

const Background = styled(LazyLoadImage)`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`

const Wrapper = styled(FlexBox)`
  width: 100%;
  background: ${({ theme }) => theme.colors.variants.neutralDark1};
  padding: 2.875rem 10vw;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 3;

  @media (max-width: 1199px) {
    padding-right: 8.454vw;
    padding-left: 8.454vw;
  }
`

const LeftSide = styled.div`
  width: 68%;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  padding-right: 8.611vw;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    width: 100%;
    padding-right: 0;
  }
`

const Created = styled.div`
  color: ${({ theme }) => theme.colors.variants.primaryLight1};
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.1125rem;
  line-height: 0.9375rem;
  margin-bottom: 0.5625rem;
`

const Title = styled.h1`
  max-width: 460px;
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-size: 1.875rem;
  letter-spacing: 0.0563rem;
  line-height: 2.25rem;
`

const RightSide = styled(FlexBox)`
  width: 32%;

  @media (max-width: 1199px) {
    width: 100%;
    margin-top: 1.5rem;
  }
`

const Label = styled.label`
  color: ${({ theme }) => theme.colors.variants.neutralLight4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 0.75rem;
  font-weight: 600;
  letter-spacing: 0.1125rem;
  line-height: 0.9375rem;
  margin-right: 1.25rem;
  text-transform: uppercase;

  @media (max-width: 424px) {
    display: none;
  }
`

const Share = styled(FlexBox)`
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    border: 2px solid ${rgba(theme.colors.variants.neutralLight4, 0.2)} !important;
    border-radius: 50%;
    margin-right: 0.75rem;
    transition: 0.3s ease-in-out;
    &:hover {
      background: ${rgba(theme.colors.variants.neutralLight4, 0.2)} !important;
      border-color: transparent !important;
    }
    &:last-of-type {
      margin-right: 0;
    }
    svg {
      width: auto;
      height: 1.25rem;
      stroke: ${({ theme }) => theme.colors.variants.neutralLight4};
      stroke-width: 2;
      &.whatsapp {
        stroke-width: 1;
      }
    }

    @media (max-width: 1439px) {
      margin-right: 0.5rem;
    }
  }
`
