import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'

import { Paragraph, Props as ParagraphProps } from './Paragraph'
import { Post, Props as PostProps } from './Post'

export interface Props {
  languageCode: string
  latestPost?: PostProps[]
  paragraphs: ParagraphProps[]
}

export const ArticleParagraph = memo(function ArticleParagraph({
  languageCode,
  latestPost,
  paragraphs,
}: Props) {
  if (!paragraphs) {
    return null
  }

  return (
    <Container row wrap tag="section">
      <LeftSide>
        {paragraphs.map((item, index) => (
          <Paragraph key={index} {...item} />
        ))}
      </LeftSide>
      <RightSide>
        {latestPost ? (
          <>
            <Label>{useVocabularyData('latest-post', languageCode)}</Label>
            <Posts>
              {latestPost
                .sort((a: any, b: any) => (a.created < b.created ? 1 : -1))
                .slice(0, 3)
                .map((item, index) => (
                  <Post key={index} {...item} />
                ))}
            </Posts>
          </>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 1.875rem 10vw 8.125rem;

  @media (max-width: 1199px) {
    padding: 1.875rem 8.454vw 5.625rem;
  }
`

const LeftSide = styled.div`
  width: 68%;
  padding-right: 8.611vw;

  @media (max-width: 1023px) {
    width: 100%;
    padding-right: 0;
  }
`

const RightSide = styled.div`
  width: 32%;
  padding-top: 2rem;

  @media (max-width: 1023px) {
    width: 100%;
    margin-top: 3.75rem;
    padding-top: 0;
  }
`

const Label = styled.label`
  display: block;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-size: 1.375rem;
  font-weight: 500;
  line-height: 1.875rem;
  margin-bottom: 1.25rem;
`

const Posts = styled.div``
